<template>
  <div class="user-profile" :class="{active: isSelected}">
    <div class="ava">
      <img :src="avatar" alt="#">
      <div class="dot" v-if="hasNotification"/>
    </div>
    <div class="nickname">{{ nickname }}</div>
  </div>
</template>

<script>
export default {
  name: "profileFriendsChatUserCard",
  props: {
    hasNotification: Boolean,
    nickname: String,
    avatar: String,
    isSelected: Boolean
  }
}
</script>

<style scoped>

</style>