<template>
  <div class="profileFriends">
    <div class="tabs">
      <ul class="tabs__main">
        <li v-for="tab in tabs" :key="tab.id"
            :class="{active:selectedTab === tab }"
            @click="selectedTab = tab">
          {{ tab.name }}
        </li>
      </ul>
      <ul class="tabs__appendix">
        <li :class="{active:selectedTab.id === 'requests'}"
            @click="selectedTab = {name: 'Заявки', id: 'requests'}">
          <media :query="{minWidth: 581}">Заявки в друзья</media>
          <media :query="{maxWidth: 580}">Заявки</media>
        </li>
      </ul>
    </div>
    <profile-friends-tab :selected-tab="selectedTab"/>
    <div class="listed-chat-container">
      <profile-friends-chat/>
    </div>
  </div>
</template>

<script>

import ProfileFriendsTab from "@/components/profile/content/profileFriendsTab"
import Media from 'vue-media'
import ProfileFriendsChat from "@/components/profile/content/profileFriendsChat";

export default {
  name: "profileFriends",
  components: {ProfileFriendsChat, ProfileFriendsTab, Media},
  data() {
    return {
      tabs: [
        {name: 'Все', id: 'all'},
        {name: 'Blizzard Battle.net', id: 'bnet'},
        {name: 'Steam', id: 'steam'},
        {name: 'EpicGames', id: 'egs'}
      ],
      selectedTab: {name: 'Все', id: 'all'},
    }
  },
}
</script>

<style lang="scss" scoped>
.profileFriends {
  margin-top: 60px;
  height: 100%;

  .listed-chat-container {
    position: fixed;
    bottom: 40px;
    right: 4.8vw;
    max-width: 705px;
    width: 100%;
    z-index: 20;
  }
}
</style>