<template>
  <div>
    <div class="tabs__content">
      <transition name="default">
        <label class="friends-search"
               v-if="selectedFilter !== 'all' && selectedFilter !== 'requests'">
          <input type="text"
                 :placeholder="`Узнайте, кто из ваших друзей в ${selectedTab.name} играет на SGL`" required>
          <button class="filled__grey">Найти друзей с Steam</button>
        </label>
      </transition>
      <div v-for="friend in friendsFilter" :key="friend.id" class="cards__wrap">
        <div class="friend-card">
          <div class="ava">
            <img src="~@/img/testAvatar/american-football-player.png" alt="#">
            <div class="dot"></div>
          </div>
          <div class="name">{{ friend.name }}</div>
          <div class="message">
            <svg class="send" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="4" fill="#515151"/>
              <path
                  d="M12.8264 18.3712L10 8.93945L30 19.2032H20.5452L12.8264 18.3712ZM12.8264 21.5593L10 30.9395L30 20.6757H20.538L12.8264 21.5593Z"
                  fill="white"/>
            </svg>
            <div class="menu">
              <div class="option">Написать сообщение</div>
            </div>
          </div>
          <country-flag :country="friend.country" rounded/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import axios from "axios";

export default {
  name: "profileFriendsTab",
  components: {CountryFlag},
  data() {
    return {
      friends: [],
      filters: ['bnet', 'steam', 'egs'],
      selectedFilter: ''
    }
  },
  watch: {
    selectedTab: function () {
      this.selectedFilter = this.$props.selectedTab.id
    }
  },
  mounted() {
    axios.get('/friendsArray.json', {baseURL: window.location.origin})
        .then((response) => {
          (this.friends = response.data)
          return response.data;
        })
        .catch((error) => {
          throw error.response.data;
        });
  },
  computed: {
    friendsFilter() {
      return this.selectedFilter !== "all"
          ? this.friends.filter(item => item.links[this.selectedFilter])
          : this.friends;
    },
  },
  props: {
    selectedTab: Object
  }
}
</script>

<style scoped lang="scss">
.normal-flag {
  transform-origin: right;
}
</style>