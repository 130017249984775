<template>
  <div class="listed-chat" ref="chat">
    <div class="users-list" :class="{active: chatShow}">
      <div class="head">
        <profile-friends-chat-user-card
            :has-notification="true"
            :nickname="'ThRashEr03'"
            :is-selected="false"
            :avatar="'https://en.meming.world/images/en/thumb/d/de/Leonardo_DiCaprio_Laughing.jpg/300px-Leonardo_DiCaprio_Laughing.jpg'"/>
      </div>
      <div class="list-wrapper">
        <profile-friends-chat-user-card
            v-for="card in usersList"
            :key="card.nickname"
            :has-notification="card.hasNotification"
            :nickname="card.nickname"
            :avatar="card.avatar"
            :is-selected="card.isSelected"/>
      </div>
    </div>
    <div class="chat-body">
      <div class="head" ref="head"
           @click="chatShow = !chatShow; getChatHeight()"
           :class="{active: chatShow}">
        <div class="user-nickname">Диалог с {{ selectedUser }}</div>
        <svg :class="{active: !chatShow}" width="16" height="16" viewBox="0 0 16 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M4 10L8 6L12 10" stroke="white" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="body">
        <div class="body-wrapper">
          <div class="date">Сегодня</div>
          <div class="message">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Вопрос</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Вопрос</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
          <div class="message reply">
            <div class="ava"><img src="~@/img/testAvatar/pilot.png" alt=""></div>
            <div class="text">Ответ</div>
            <div class="post-time">12:52</div>
          </div>
        </div>
      </div>
      <div class="foot">
        <input type="text">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M8.8264 14.3712L6 4.93945L26 15.2032H16.5452L8.8264 14.3712ZM8.8264 17.5593L6 26.9395L26 16.6757H16.538L8.8264 17.5593Z"
              fill="white"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileFriendsChatUserCard from "@/components/profile/content/profileFriendsChatUserCard";

export default {
  name: "profileFriendsChat",
  components: {ProfileFriendsChatUserCard},
  data() {
    return {
      selectedUser: "-Vtz-",
      usersList: [
        {
          nickname: "-Vtz-",
          hasNotification: true,
          avatar: "https://en.meming.world/images/en/thumb/d/de/Leonardo_DiCaprio_Laughing.jpg/300px-Leonardo_DiCaprio_Laughing.jpg"
        },
        {
          isSelected: true,
          nickname: "Karuma_San",
          hasNotification: false,
          avatar: "https://en.meming.world/images/en/thumb/d/de/Leonardo_DiCaprio_Laughing.jpg/300px-Leonardo_DiCaprio_Laughing.jpg"
        },
        {
          nickname: "Reaper_Death",
          hasNotification: false,
          avatar: "https://en.meming.world/images/en/thumb/d/de/Leonardo_DiCaprio_Laughing.jpg/300px-Leonardo_DiCaprio_Laughing.jpg"
        },
        {
          nickname: "SanDDx",
          hasNotification: false,
          avatar: "https://en.meming.world/images/en/thumb/d/de/Leonardo_DiCaprio_Laughing.jpg/300px-Leonardo_DiCaprio_Laughing.jpg"
        },

      ],
      chatShow: false
    }
  },
  methods: {
    getChatHeight: function () {
      let chatBlock = this.$refs.chat;
      let chatHeight = this.chatShow === true ? chatBlock.scrollHeight : 64;
      chatBlock.style.maxHeight = chatHeight + 'px';
    }
  }
}
</script>

<style lang="scss" scoped>
</style>